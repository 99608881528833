//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import NextImage from "next/image";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useEffect, useState, useLayoutEffect, useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Tag, Col, Card, Space, Skeleton, Typography, Image } from "antd";
import { useParams } from "next/navigation";

//? Assets --------------------------------------------------------------------->
import "./ProductCard.scss";
import Points from "../../../../assets/images/logos/sanaullaPoints.svg";
import DefaultImage from "../../../../assets/images/defaultImages/DefaultProductImage.jpg";
import { removeCommasAndConvertToNumber } from "../../../../functions/HelperFunctions/index";
import FlashIcon from "../../../../assets/images/flashIcon/flashIcon.svg";
//? Library Constants ---------------------------------------------------------->
const { Meta } = Card;
const { Text } = Typography;

const ProductCard = ({
  keyIndex,
  product,
  type,
  userAgent,
  megaSearchMenuCheck,
  categoryName,
  storeCuurency,
}) => {
  const [hoverProduct, setHoverProduct] = useState(false);
  const [currencyName, setCurrencyName] = useState(storeCuurency || "");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    product?.images[0] + "%3Fwidth%3D440" ?? DefaultImage
  );

  const { isDesktop, isTablet, isMobile } = userAgent;

  const defaultCurrency = useSelector(
    (state) => state.multiLocation.defaultCurrency
  );
  const cookiesCurrency = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");

  const CD_status = useMemo(
    () => product?.features?.find((feature) => feature?.name === "CD Status"),
    [product?.features]
  );

  const DeliveryTime = useMemo(
    () =>
      product?.features?.find((feature) => feature?.name === "Delivery Time"),
    [product?.features]
  );
  let collectionName = categoryName
    ? categoryName
    : // : localStorage?.getItem("collection_name")
      // ? localStorage?.getItem("collection_name")
      product?.category[0]?.title;
  // let collectionName = categoryName
  //   ? categoryName
  //   : localStorage.getItem("collection_name")
  //   ? localStorage.getItem("collection_name")
  //   : product?.category?.[0]?.title
  //   ? product?.category?.[0]?.title
  //   : undefined;
  function extractDateAfterHyphen(deliveryDateString) {
    const parts = deliveryDateString?.split(" - ");
    return parts?.length > 1 ? parts[1] : null;
  }

  // Example delivery date from backend
  const deliveryDate = product?.delivery_date;
  const dateAfterHyphen = extractDateAfterHyphen(deliveryDate);

  function parseDate(dateString) {
    if (!dateString) {
      console.error("Invalid date string:", dateString);
      return null;
    }

    // Remove commas and extra spaces, then split
    const parts = dateString.replace(",", "").trim().split(" ");
    if (parts.length !== 3) {
      console.error("Date string does not match expected format:", dateString);
      return null;
    }

    const day = parseInt(parts[0], 10);
    const monthStr = parts[1];
    const year = parseInt(parts[2], 10);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = monthNames.indexOf(monthStr);

    return new Date(Date.UTC(year, monthIndex, day));
  }

  function currentFormatDate() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    return `${day} ${month}, ${year}`;
  }

  function isDateWithin10DaysOfDelivery(currentDateStr, deliveryDateStr) {
    const currentDate = parseDate(currentDateStr);
    const deliveryDate = parseDate(deliveryDateStr);

    if (!currentDate || !deliveryDate) {
      console.error("Invalid date(s) provided");
      return false;
    }

    // Calculate the difference in milliseconds and then convert to days
    const diffTime = Math.abs(currentDate.getTime() - deliveryDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 10;
  }

  const currentDateStr = currentFormatDate();

  useEffect(() => {
    if (cookiesCurrency) {
      setCurrencyName(cookiesCurrency);
    } else if (defaultCurrency) {
      setCurrencyName(defaultCurrency);
    }
  }, [defaultCurrency, cookiesCurrency]);

  const discountPercentage = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      product?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      product?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return "";
  };
  const googleAnalytics = () => {
    if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
      /**
       * Call this function when a user clicks on a product link. This function uses the event
       * callback datalayer variable to handle navigation after the ecommerce data has been sent
       * to Google Analytics.
       * @param {Object} productObj An object representing a product.
       */
      if (typeof window !== "undefined" && window?.dataLayer) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: "select_item",
          ecommerce: {
            item_list_name: collectionName,
            // click: {
            // actionField: { list: "Search Results" },
            items: [
              {
                item_id: product?.id,
                item_name: product?.title,
                index: keyIndex,
                price: removeCommasAndConvertToNumber(
                  product?.variant_detail?.discounted_price
                ),
                item_brand: product?.brand?.[0]?.title ?? "No Brand",
                item_category: collectionName ? collectionName : undefined,
                item_variant: product?.variant_detail?.variant_id,
                location_id: defaultgeoLocationCountry,
              },
            ],
            // },
          },
        });
      }
    }
  };
  const onMouseOverHandler = () => {
    if (product?.images?.length > 1) {
      setImageSrc(product.images[1] + "%3Fwidth%3D440");
      setHoverProduct(true);
    }
  };

  const onMouseOutHandler = () => {
    setImageSrc(product?.images[0] + "%3Fwidth%3D440" ?? DefaultImage);
    setHoverProduct(false);
  };

  const handleImageError = () => {
    setImageSrc(
      product?.images?.length > 0
        ? hoverProduct
          ? product.images[1]
          : product.images[0]
        : DefaultImage
    );
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    transition: "transform 0.6s ease-in-out",
    transform: hoverProduct ? "scale(1.02)" : "scale(1)",
    // opacity: hoverProduct ? 1 : 0.8,
  };

  const onImageLoad = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    setImageSrc(product.images[0] + "%3Fwidth%3D440");
  }, [product]);
  return (
    <Col className="product_card" style={{ width: "100%" }}>
      {/* <Link href={"/" + product?.handle}> */}
      <Card
        hoverable
        onClick={() => googleAnalytics()}
        cover={
          <Link
            href={"/" + product?.handle}
            style={{
              height:
                !imageLoaded && isMobile
                  ? "207px"
                  : !imageLoaded && !isMobile
                  ? "360px"
                  : "",
            }}
          >
            {product?.sold_out ? (
              <Col
                className={
                  !isMobile || isTablet
                    ? "outOfStock_container"
                    : "outOfStock_container mobile_outOfStock_container"
                }
              >
                <Text
                  className={
                    !isMobile || isTablet
                      ? "outOfStock_tag flexCenter"
                      : "outOfStock_tag flexCenter font12"
                  }
                >
                  Out Of Stock
                </Text>
              </Col>
            ) : null}
            {/* {!imageLoaded ? (
              <div
                style={{
                  width: "100%",
                  height: isMobile ? "207px" : "360px",
                  background: "#f0f0f0",
                  position: "relative",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                }}
              ></div>
            ) : null} */}
            <Image
              preview={false}
              src={imageSrc}
              alt={product?.title}
              onLoad={onImageLoad}
              onError={handleImageError}
              onMouseOver={onMouseOverHandler}
              onMouseOut={onMouseOutHandler}
              style={{
                // display: imageLoaded ? "block" : "none",
                width: "100%",
                height: "auto",
                objectFit: "cover",
                transition: "transform 0.6s ease-in-out",
                transform: hoverProduct ? "scale(1.02)" : "scale(1)",
              }}
            />
          </Link>
        }
      >
        <Meta
          title={
            <Link href={"/" + product?.handle}>
              <Text
                className={
                  !isDesktop
                    ? type !== "homepage"
                      ? "mobile_product_title font11 product_title_two_lines"
                      : "mobile_product_title_homepage font12"
                    : type !== "homepage"
                    ? "product_title product_title_two_lines"
                    : "product_title product_title_homepage"
                }
              >
                {product?.title}
              </Text>
            </Link>
          }
          description={
            <Link href={"/" + product?.handle}>
              <Space
                direction="vertical"
                className="width100 ant-card-meta-description-bottom"
              >
                <Space className="flexBetween">
                  <Space>
                    {product?.variant_detail?.discounted_price && (
                      <Text
                        className={
                          !isDesktop ? "product_price_mobile" : "product_price"
                        }
                      >
                        <span>{currencyName + " "}</span>
                        <span>{product?.variant_detail?.discounted_price}</span>
                      </Text>
                    )}

                    {removeCommasAndConvertToNumber(
                      product?.variant_detail?.discounted_price
                    ) <
                      removeCommasAndConvertToNumber(
                        product?.variant_detail?.original_price
                      ) && (
                      <>
                        <Text delete type="secondary">
                          {product?.variant_detail?.original_price}
                        </Text>
                        <Tag
                          className={
                            !isMobile || isTablet
                              ? "discount_tag"
                              : "mobile_discount_tag"
                          }
                        >
                          <Text className={isMobile || (isTablet && "font11")}>
                            {discountPercentage > 1
                              ? discountPercentage()
                              : discountPercentage()}
                          </Text>
                        </Tag>
                      </>
                    )}
                  </Space>

                  {/* <Space>
                    {product?.variant_detail?.discounted_price >=
                      product?.variant_detail?.original_price &&
                      product?.loyalty_points > 0 && (
                        <div
                          style={{
                            height: "18px",
                            display: "flex",
                            padding: "0 7px",
                            minWidth: "55px",
                            alignItems: "center",
                            background: "#2A1E5C",
                            borderRadius: "9px 0px 9px 9px",
                          }}
                        >
                          <NextImage
                            width={12}
                            height={12}
                            src={Points}
                            alt="LoyaltyPoints"
                          />
                          <span
                            className="font10Bold"
                            style={{ color: "white", paddingLeft: "5px" }}
                          >
                            {product?.loyalty_points}
                          </span>
                        </div>
                      )}
                  </Space> */}
                </Space>

                {!(
                  product?.variant_detail?.discounted_price <
                  product?.variant_detail?.original_price
                ) &&
                  megaSearchMenuCheck && (
                    <Space className="flexBetween"></Space>
                  )}
                {type !== "homepage" && !megaSearchMenuCheck && (
                  <Space
                    className={
                      isDesktop
                        ? "product_tags_container"
                        : "product_tags_container_mobile"
                    }
                  >
                    {product?.online_only && !product?.sold_out && (
                      <Tag className="onlineOnly_tag">
                        <Text className={!isDesktop && "font8"}>
                          Online Only
                        </Text>
                      </Tag>
                    )}
                    {product?.free_shipping && !product?.sold_out && (
                      <Tag className="freeShipping_tag">
                        <Text className={!isDesktop && "font8"}>
                          Free Shipping
                        </Text>
                      </Tag>
                    )}

                    {/* {CD_status?.value === "CN" && */}
                    {!product?.express_delivery ? (
                      <>
                        {/* {isDateWithin10DaysOfDelivery(
                            currentDateStr,
                            dateAfterHyphen
                          ) */}
                        {/* ? */}
                        {/* !product?.sold_out && ( */}
                        <Tag className="freeShipping_tag">
                          <Text className={!isDesktop && "font8"}>
                            Ship: {dateAfterHyphen.split(",")[0]}
                          </Text>
                        </Tag>
                        {/* )
                            : null} */}
                      </>
                    ) : (
                      !product?.sold_out &&
                      product?.express_delivery && (
                        <Tag className="expressDelivery_tag">
                          <Text
                            className={!isDesktop && "font8"}
                            style={{ color: "white", fontStyle: "italic" }}
                          >
                            Express Delivery
                          </Text>
                          <Image
                            src={FlashIcon.src}
                            height={!isDesktop ? 10 : 15}
                            width="auto"
                            alt="express delivery"
                          />
                        </Tag>
                      )
                    )}

                    {/* {CD_status?.value === "CD" &&
                      DeliveryTime?.value <= 10 &&
                      !product?.sold_out && (
                        <Tag className="freeShipping_tag">
                          <Text className={!isDesktop && "font8"}>
                            {"Ship: " + dateAfterHyphen.split(",")[0]}
                          </Text>
                        </Tag>
                      )} */}
                  </Space>
                )}
              </Space>
            </Link>
          }
        />
      </Card>
      {/* </Link> */}
    </Col>
  );
};

export default ProductCard;
