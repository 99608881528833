//? Library Imports ------------------------------------------------------------>
import { useState, useEffect } from "react";
import { Col, Row, Space, Button, Modal, Typography } from "antd";
import { signIn, useSession, signOut } from "next-auth/react";

//? Assets --------------------------------------------------------------------->
import "./LoginPage.scss";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";

//? Modules -------------------------------------------------------------------->
import LoginForm from "./Modules/LoginForm";
import SignupForm from "./Modules/SignupForm";

//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function LoginPage({ ShowLoginModal, setShowLoginModal }) {
  // const [ShowModal, setShowModal] = useState(false);
  const [ModalType, setModalType] = useState("login");

  useEffect(() => {
    return () => {};
  }, [ShowLoginModal]);

  return (
    <>
      <Modal
        centered
        width={533}
        footer={false}
        open={ShowLoginModal}
        className="login-modal"
        onOk={() => {
          setShowLoginModal(false);
          setModalType("login");
        }}
        onCancel={() => {
          setShowLoginModal(false);
          setModalType("login");
        }}
        title={
          ModalType === "login" ? (
            <Col span={24}>
              <Text
                style={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  fontWeight: "600",
                }}
              >
                Signin
              </Text>
            </Col>
          ) : (
            <Col span={24}>
              <Text
                style={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  fontWeight: "600",
                }}
              >
                Signup
              </Text>
            </Col>
          )
        }
      >
        {/* Form */}
        {ModalType === "login" ? (
          <LoginForm setShowLoginModal={setShowLoginModal} />
        ) : (
          <SignupForm setShowLoginModal={setShowLoginModal} />
        )}
        {/* Content Under Form */}
        <Row
          justify={"center"}
          align={"center"}
          gutter={[0, 7]}
          className="modalfooter"
        >
          <Col span={24} align="middle">
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                lineHeight: "21px",
              }}
            >
              Or Login With
            </Text>
          </Col>
          <Col span={24} align="middle">
            <Space className="flexCenter">
              <Button
                icon={
                  <AiOutlineGoogle
                    style={{ fontSize: "16px", color: "#cf4332" }}
                  />
                }
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  signIn("google");
                }}
              >
                Google
              </Button>
              <Button
                icon={
                  <FaFacebookF style={{ fontSize: "16px", color: "#337fff" }} />
                }
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  signIn("facebook");
                }}
              >
                Facebook
              </Button>
            </Space>
          </Col>
          <Col span={24} align="middle" style={{ marginTop: "20px" }}>
            <Text
              style={{
                fontSize: "16px",
                lineHeight: "25px",
                fontWeight: "600",
              }}
            >
              {ModalType === "login"
                ? "Create an Account at"
                : "Already have an account"}{" "}
              &nbsp;
              <Text
                style={{
                  color: "#FF3F6C",
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={() => {
                  {
                    ModalType === "login"
                      ? setModalType("signup")
                      : setModalType("login");
                  }
                }}
              >
                {ModalType === "login" ? "SignUp" : "Login"}
              </Text>
            </Text>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
